import React, { Component } from "react"
import Layout from "../components/layout"
import HeaderBanner from "../components/common/headerBanner/headerBanner"
import CTABanner from "../components/common/ctaBanner/ctaBanner"
import * as styles from "./contact-us.module.scss"
import phone from "../../assets/support-phone.png"
import fax from "../../assets/support-fax.png"
import chat from "../../assets/support-chat.png"
import ThreeColumnGrid from "../components/common/threeColumnGrid/threeColumnGrid"
import Breadcrumb from "../components/common/breadcrumb/breadcrumb"
import { TM } from "../components/common/typography/trademark"

const columnItems = [
  {
    heading: "Email",
    textArray: [
      {
        text: "Sales / VARs:",
        link: "sales@ironcap.ca",
        url: "sales@ironcap.ca",
      },
      {
        text: "Technical Support:",
        link: "help@ironcap.ca",
        url: "help@ironcap.ca",
      },
      {
        text: "Customer Service:",
        link: "customerservice@ironcap.ca",
        url: "customerservice@ironcap.ca",
      },
      {
        text: "Investor Relations:",
        link: "ir@ironcap.ca",
        url: "ir@ironcap.ca",
      },
      {
        text: "Marketing:",
        link: "marketing@ironcap.ca",
        url: "marketing@ironcap.ca",
      },
      { text: "Human Resources:", link: "hr@ironcap.ca", url: "hr@ironcap.ca" },
    ],
  },
  {
    heading: "Address",
    textArray: [
      {
        text:
          "01 Communique\n789 Don Mills Road, Suite #700\nToronto, Ontario, M3C 1T5\nCanada",
      },
    ],
  },
  {
    heading: "Office Hours",
    textArray: [
      {
        text: "Monday - Friday\n9am to 6pm EST",
      },
    ],
  },
]

class ContactUs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ie: false,
    }
  }
  componentDidMount() {
    if (typeof window !== `undefined`) {
      let isIE = /*@cc_on!@*/ false || !!document.documentMode
      this.setState({
        ie: isIE,
      })
    }
  }

  openChat = () => {
    window.open(
      "http://www.imoncall.com/ironcapchat",
      <>
        IronCAP
        <TM /> Live Chat
      </>,
      "width=500,height=600"
    )
  }
  render() {
    return (
      <Layout>
        <HeaderBanner title="Contact Us" />

        <div className={styles.pageContainer}>
          <Breadcrumb firstLink="/contact-us" firstLinkTitle="Contact Us" />
          <div className={styles.pageText}>
            <p>We love to hear from our customers or partners.</p>
            <p>Call or email us with your questions or comments!</p>
          </div>

          <div className={styles.contactGrid}>
            <div className={this.state.ie ? styles.contactGridItemIE : null}>
              <img src={phone} alt="Phone" />
              <div className={styles.contactText}>
                <p>1-(800)-668-2185</p>
              </div>
            </div>
            <div className={this.state.ie ? styles.contactGridItemIE : null}>
              <img src={fax} alt="Fax" />
              <div className={styles.contactText}>
                <p>905-795-0101</p>
              </div>
            </div>
            <a
              href="#"
              target="_blank"
              className={this.state.ie ? styles.contactGridItemIE : null}
              onClick={this.openChat}
            >
              <div>
                <img src={chat} alt="Pre-sales chat" />
                <div className={styles.contactText}>
                  <div className={styles.chatText}>
                    <p>Live Chat</p>
                    <p>Pre-Sales</p>
                  </div>
                </div>
              </div>
            </a>
            <a
              href="http://www.imoncall.com/ironcapchat"
              target="_blank"
              className={this.state.ie ? styles.contactGridItemIE : null}
            >
              <div>
                <img src={chat} alt="After-sales chat" />
                <div className={styles.contactText}>
                  <div className={styles.chatText}>
                    <p>Live Chat</p>
                    <p>After-Sales</p>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <ThreeColumnGrid columnItems={columnItems} IE={this.state.ie} />
          <CTABanner
            tagline="Step Into The Future"
            CTA="video"
            CTADetail="how video"
          />
        </div>
      </Layout>
    )
  }
}
export default ContactUs
