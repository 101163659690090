import React, { useState, useEffect } from "react"

import * as styles from "./threeColumnGrid.module.scss"
import ModalVideo from "react-modal-video"

const ThreeColumnGrid = ({ columnItems, IE }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [isBrowser, setIsBrowser] = useState(false)
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsBrowser(true)
    }
  })

  return (
    <div className={IE ? styles.contentGridIE : styles.contentGrid}>
      {columnItems.map((column, i) => {
        return (
          <div key={i}>
            <p className={styles.columnHeader}>{column.heading}</p>
            {column.textArray.map((textItem, i) => {
              const url = textItem.link
                ? textItem.url.includes("@ironcap.ca")
                  ? `mailto:${textItem.url}`
                  : textItem.url
                : null

              return (
                <div className={styles.columnItem} key={i}>
                  {textItem.text && <p>{textItem.text}</p>}
                  <a
                    href={
                      textItem.link &&
                      textItem.link.toString().includes("Tutorial")
                        ? "#"
                        : url
                    }
                    target={
                      !column.heading.includes("FAQ") &&
                      textItem.link &&
                      !textItem.link.toString().includes("Tutorial")
                        ? "_blank"
                        : null
                    }
                    onClick={() =>
                      textItem.link &&
                      textItem.link.toString().includes("Tutorial") &&
                      setModalOpen(true)
                    }
                  >
                    {textItem.link}
                  </a>
                </div>
              )
            })}
          </div>
        )
      })}
      {isBrowser && (
        <ModalVideo
          channel="youtube"
          isOpen={modalOpen}
          videoId="iKSD8MYShsY"
          onClose={() => setModalOpen(false)}
        />
      )}
    </div>
  )
}

export default ThreeColumnGrid
